import React, { Suspense, lazy } from 'react';
import {BrowserRouter as Router,Redirect,Switch,Route} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import '../App.css';
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//import Sticky from './Sticky';
const Home = lazy(() => import('./Home'));
const NotFound = lazy(() => import("./NotFound"));
const Demo = lazy(() => import("./Demo"));
const Login = lazy(() => import("./LoginPage/Login"));
const Logout = lazy(() => import("./LoginPage/Logout"));
const Register = lazy(() => import("./LoginPage/Register"));
const ForgetPassword = lazy(() => import("./LoginPage/ForgetPassword"));
const ChangePassword = lazy(() => import("./LoginPage/ChangePassword"));
const AdminDashboard = lazy(() => import("./MyAccount/AdminDashboard"));
const AddBooks = lazy(() => import("./MyAccount/AddBooks"));
const AddBooksPack = lazy(() => import("./MyAccount/AddBooksPack"));
const BookRecepits = lazy(() => import("./MyAccount/BookRecepits"));
const AudioRecepits = lazy(() => import("./MyAccount/AudioRecepits"));
const VideoRecepits = lazy(() => import("./MyAccount/VideoRecepits"));
const PublisherRecepits = lazy(() => import("./MyAccount/PublisherRecepits"));
const AddAudioBooks = lazy(() => import("./MyAccount/AddAudioBooks"));
const AddBooksTrailer = lazy(() => import("./MyAccount/AddBooksTrailer"));
const Addpublisher = lazy(() => import("./MyAccount/Addpublisher"));
const EditBooks = lazy(() => import("./MyAccount/EditBooks"));
const EditAudio = lazy(() => import("./MyAccount/EditAudio"));
const EditVideo = lazy(() => import("./MyAccount/EditVideo"));
const EditPublisher = lazy(() => import("./MyAccount/EditPublisher"));
const EditProfile = lazy(() => import("./MyAccount/EditProfile"));
const Adminpages = lazy(() => import("./MyAccount/Adminpages"));
const BooksPayments = lazy(() => import("./MyAccount/BooksPayments"));
const AudioPayments = lazy(() => import("./MyAccount/AudioPayments"));
const VideoPayments = lazy(() => import("./MyAccount/VideoPayments"));
const PublisherPayments = lazy(() => import("./MyAccount/PublisherPayments"));
const BooksPaymentsupgrade = lazy(() => import("./MyAccount/BooksPaymentsupgrade"));
const StripeSuccessbooks = lazy(() => import("./MyAccount/payments/StripeSuccessbooks"));
const StripeCancelbooks = lazy(() => import("./MyAccount/payments/StripeCancelbooks"));

const StripeCancelupgradebooks = lazy(() => import("./MyAccount/payments/StripeCancelupgradebooks"));

const PaypalSuccessbooks = lazy(() => import("./MyAccount/payments/PaypalSuccessbooks"));
const PaypalCancelbooks = lazy(() => import("./MyAccount/payments/PaypalCancelbooks"));

const PaypalSuccessaudio = lazy(() => import("./MyAccount/payments/PaypalSuccessaudio"));
const PaypalCancelaudio = lazy(() => import("./MyAccount/payments/PaypalCancelaudio"));
const PaypalSuccessvideo = lazy(() => import("./MyAccount/payments/PaypalSuccessvideo"));
const PaypalCancelvideo = lazy(() => import("./MyAccount/payments/PaypalCancelvideo"));
const PaypalSuccesspublisher = lazy(() => import("./MyAccount/payments/PaypalSuccesspublisher"));
const PaypalCancelpublisher = lazy(() => import("./MyAccount/payments/PaypalCancelpublisher"));

const BookCancelled = lazy(() => import("./MyAccount/payments/BookCancelled"));
const AudioCancelled = lazy(() => import("./MyAccount/payments/AudioCancelled"));
const VideoCancelled = lazy(() => import("./MyAccount/payments/VideoCancelled"));
const PublisherCancelled = lazy(() => import("./MyAccount/payments/PublisherCancelled"));

const StripeSuccessaudio = lazy(() => import("./MyAccount/payments/StripeSuccessaudio"));
const StripeCancelaudio = lazy(() => import("./MyAccount/payments/StripeCancelaudio"));

const StripeSuccessvideo = lazy(() => import("./MyAccount/payments/StripeSuccessvideo"));
const StripeCancelvideo = lazy(() => import("./MyAccount/payments/StripeCancelvideo"));

const StripeSuccesspublisher = lazy(() => import("./MyAccount/payments/StripeSuccesspublisher"));
const StripeCancelpublisher = lazy(() => import("./MyAccount/payments/StripeCancelpublisher"));

const Successbooks = lazy(() => import("./MyAccount/payments/Successbooks"));
const Cancelbooks = lazy(() => import("./MyAccount/payments/Cancelbooks"));
const BooksPreview = lazy(() => import("./BooksPreview"));
const AudioPreview = lazy(() => import("./AudioPreview"));
const VideoPreview = lazy(() => import("./VideoPreview"));
const PublisherPreview = lazy(() => import("./PublisherPreview"));
const ImpressiveBooks = lazy(() => import("./ImpressiveBooks"));
const BestDealsBooks = lazy(() => import("./BestDealsBooks"));
const FeaturedBooks = lazy(() => import("./FeaturedBooks"));
const StandardBooks = lazy(() => import("./StandardBooks"));
const AudioBooks = lazy(() => import("./AudioBooks"));
const BooksTrailers = lazy(() => import("./BooksTrailers"));
const Publishers = lazy(() => import("./Publishers"));
const Authors = lazy(() => import("./Authors"));
const Categeorylist = lazy(() => import("./Categeorylist"));
const AuthorPage = lazy(() => import("./AuthorPage"));
const SearchAllBooks = lazy(() => import("./search/SearchAllBooks"));
const SearchAllAudio = lazy(() => import("./search/SearchAllAudio"));
const SearchAllVideos = lazy(() => import("./search/SearchAllVideos"));
const SearchAllPublisher = lazy(() => import("./search/SearchAllPublisher"));
const Contmail = lazy(() => import("./Contmail"));
const Partnersite = lazy(() => import("./Partnersite"));

function Dashboard() {
  return (<>   
    <Router basename="/">
      <Suspense fallback={<div className="divLoaderwww">
        <svg className="svgLoader" viewBox="0 0 100 100" width="7em" height="7em">
          <path ng-attr-d="{{config.pathCmd}}" ng-attr-fill="{{config.color}}" stroke="none" d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#f1592b" transform="rotate(179.719 50 51)"><animateTransform attributeName="transform" type="rotate" calcMode="linear" values="0 50 51;360 50 51" keyTimes="0;1" dur="1s" begin="0s" repeatCount="indefinite"></animateTransform></path>
        </svg>
      </div>}>
        <ToastContainer />
        <Switch>
         <Route exact path="/" component={Home}/>
          <Route exact path="/Login" component={Login}/>
          <Route exact path="/Register" component={Register}/>
          <Route exact path="/AdminDashboard" component={AdminDashboard}/>
          <Route exact path="/AddBooks" component={AddBooks}/>
          <Route exact path="/AddBooksPack" component={AddBooksPack}/>
          <Route exact path="/AddAudioBooks" component={AddAudioBooks}/>
          <Route exact path="/AddBooksTrailer" component={AddBooksTrailer}/>
          <Route exact path="/Addpublisher" component={Addpublisher}/>
          <Route exact path="/ImpressiveBooks" component={ImpressiveBooks}/>
          <Route exact path="/BestDealsBooks" component={BestDealsBooks}/>
          <Route exact path="/FeaturedBooks" component={FeaturedBooks}/>
          <Route exact path="/StandardBooks" component={StandardBooks}/>
          <Route exact path="/AudioBooks" component={AudioBooks}/>
          <Route exact path="/BooksTrailers" component={BooksTrailers}/>
          <Route exact path="/Publishers" component={Publishers}/>
          <Route exact path="/Authors" component={Authors}/>
          <Route exact path="/Adminpages" component={Adminpages}/>
          <Route exact path="/Contmail" component={Contmail}/>
          <Route exact path="/Partnersite" component={Partnersite}/>
          

          <Route exact path="/ForgetPassword" component={ForgetPassword}/>
          <Route exact path="/Logout" component={Logout}/>
          <Route exact path="/Categeorylist/:categorytitle" component={Categeorylist}/>
          <Route exact path="/ChangePassword/:passkey" component={ChangePassword}/>
          <Route exact path="/BookRecepits/:Addpreview" component={BookRecepits}/>
          <Route exact path="/AudioRecepits/:Addpreview" component={AudioRecepits}/>
          <Route exact path="/VideoRecepits/:Addpreview" component={VideoRecepits}/>
          <Route exact path="/PublisherRecepits/:Addpreview" component={PublisherRecepits}/>
          <Route exact path="/BooksPreview/:Addpreview" component={BooksPreview}/>
          <Route exact path="/AudioPreview/:Addpreview" component={AudioPreview}/>
          <Route exact path="/VideoPreview/:Addpreview" component={VideoPreview}/>
          <Route exact path="/PublisherPreview/:Addpreview" component={PublisherPreview}/>
          <Route exact path="/EditBooks/:Addpreview" component={EditBooks}/>
          <Route exact path="/EditAudio/:Addpreview" component={EditAudio}/>
          <Route exact path="/EditVideo/:Addpreview" component={EditVideo}/>
          <Route exact path="/EditPublisher/:Addpreview" component={EditPublisher}/>
          <Route exact path="/EditProfile" component={EditProfile}/>
          <Route exact path="/BooksPayments/:Addpreview" component={BooksPayments}/>
          <Route exact path="/AudioPayments/:Addpreview" component={AudioPayments}/>
          <Route exact path="/VideoPayments/:Addpreview" component={VideoPayments}/>
          <Route exact path="/PublisherPayments/:Addpreview" component={PublisherPayments}/>
          <Route exact path="/BooksPaymentsupgrade/:Addpreview" component={BooksPaymentsupgrade}/>
          <Route exact path="/Successbooks/:Addpreview" component={Successbooks}/>
          <Route exact path="/StripeSuccessbooks/:Addpreview" component={StripeSuccessbooks}/>
          <Route exact path="/PaypalSuccessbooks/:Addpreview" component={PaypalSuccessbooks}/>
          <Route exact path="/StripeSuccessaudio/:Addpreview" component={StripeSuccessaudio}/>
          <Route exact path="/StripeSuccessvideo/:Addpreview" component={StripeSuccessvideo}/>
          <Route exact path="/StripeSuccesspublisher/:Addpreview" component={StripeSuccesspublisher}/>
          <Route exact path="/BookCancelled" component={BookCancelled}/>
          <Route exact path="/AudioCancelled" component={AudioCancelled}/>
          <Route exact path="/VideoCancelled" component={VideoCancelled}/>
         
          <Route exact path="/PaypalSuccessaudio/:Addpreview" component={PaypalSuccessaudio}/>
          <Route exact path="/PaypalCancelaudio/:Addpreview" component={PaypalCancelaudio}/>
          <Route exact path="/PaypalSuccessvideo/:Addpreview" component={PaypalSuccessvideo}/>
          <Route exact path="/PaypalCancelvideo/:Addpreview" component={PaypalCancelvideo}/>
          <Route exact path="/PaypalSuccesspublisher/:Addpreview" component={PaypalSuccesspublisher}/>
          <Route exact path="/PaypalCancelpublisher/:Addpreview" component={PaypalCancelpublisher}/>

          <Route exact path="/PublisherCancelled" component={PublisherCancelled}/>
          <Route exact path="/PaypalCancelbooks/:Addpreview" component={PaypalCancelbooks}/>
          <Route exact path="/StripeCancelbooks/:Addpreview" component={StripeCancelbooks}/>
          <Route exact path="/StripeCancelupgradebooks/:Addpreview" component={StripeCancelupgradebooks}/>
          <Route exact path="/StripeCancelaudio/:Addpreview" component={StripeCancelaudio}/>
          <Route exact path="/StripeCancelvideo/:Addpreview" component={StripeCancelvideo}/>
          <Route exact path="/StripeCancelpublisher/:Addpreview" component={StripeCancelpublisher}/>
          <Route exact path="/Cancelbooks/:Addpreview" component={Cancelbooks}/>
          <Route exact path="/AuthorPage/:Addpreview" component={AuthorPage}/>
          <Route exact path="/SearchAllBooks/:Addpreview" component={SearchAllBooks}/>
          <Route exact path="/SearchAllAudio/:Addpreview" component={SearchAllAudio}/>
          <Route exact path="/SearchAllVideos/:Addpreview" component={SearchAllVideos}/>
          <Route exact path="/SearchAllPublisher/:Addpreview" component={SearchAllPublisher}/>

         <Route exact path="/404" component={NotFound} />
         <Route exact path="/Demo" component={Demo} />
         <Redirect to="/404" />

        </Switch>
      </Suspense>
      {/* <Sticky /> */}
    </Router>
    
    </>
  );
}
export default Dashboard;

import React, { createContext,Component } from "react";
import axios from 'axios';
import { WEB_URL } from './Apilinks';
export const MyContext = createContext();

// Define the base URL
const Axios = axios.create({
    baseURL: WEB_URL,
});

class MyContextProvider extends Component{
    state = {
        isAuth:false,
        theUserID:null,
        theUserName:null,
        theUserEmail:null,
        theUserType:null,
    }
    componentDidMount(){
        this.isLoggedIn();
    }
  
        isLoggedIn = async () => {
            let loginID = JSON.parse(localStorage.getItem('loginID'));
            let loginName = JSON.parse(localStorage.getItem('loginName'));
            let loginEmail = JSON.parse(localStorage.getItem('loginEmail'));
            let loginType = JSON.parse(localStorage.getItem('loginType'));
            if (localStorage.getItem('loginID')) {
                this.setState({
                    ...this.state,
                    isAuth:true,
                    theUserID:loginID,
                    theUserName:loginName,
                    theUserEmail:loginEmail,
                    theUserType:loginType,
                });
            }
        }
        logoutUser = () => {
            localStorage.removeItem('loginID');
            localStorage.removeItem('loginName');
            localStorage.removeItem('loginEmail');
            localStorage.removeItem('loginType');
            this.setState({
                ...this.state,
                isAuth:false,
                theUserID:null,
                theUserName:null,
                theUserEmail:null,
                theUserType:null,
            })
            window.location.href = '/Login';
        }
        RegisterUser = async (user,userInfo,recaptcha_response) => {
            const registerqqq = await Axios.post('register.php',{
                user_email:user.email,
                user_name:userInfo.username,
                password:userInfo.password,
                subscribe:userInfo.subscribe,
                recaptcha_response:recaptcha_response,
            });
            return registerqqq.data;
        }
        RegisterUsergoogle = async (user) => {
            const RegisterUsergoogleqqq = await Axios.post('googleRegister.php',{
                user_name:user.name,
                user_email:user.email,
                password:user.googleId,
                imageUrl:user.imageUrl,
            });
            return RegisterUsergoogleqqq.data;
        }
        LoginUser = async (user) => {
            const LoginUserqqq = await Axios.post('login.php',{
                email:user.email,
                password:user.password,
            });
            return LoginUserqqq.data;
        }
    Booksaddall = async (books,categorys,catslength,purchase,purchagelength,theUserid,plans,Amounts) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',categorys);
        formData.append('categorylength',catslength.length);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        formData.append('plans',plans);
        formData.append('Amounts',Amounts);
        const Booksaddallqqq = await Axios.post('booksadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return Booksaddallqqq.data;
    }
    Booksaddallpacks = async (books,categorys,catslength,purchase,purchagelength,theUserid) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',categorys);
        formData.append('categorylength',catslength.length);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        const Booksaddallpacksqqq = await Axios.post('Booksaddpacks.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return Booksaddallpacksqqq.data;
    }
    
    bookpreviewall = async (ids) => {
        const bookpreviewall = await Axios.post('bookpreview.php',{
            bookids:ids,
        });
        return bookpreviewall.data;
    }
    Booksupdateall = async (books,categorys,catslength,purchase,purchagelength,booksidsss) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('category',categorys);
        formData.append('categorylength',catslength.length);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('booksidsss',booksidsss);
        const Booksupdateallqqq = await Axios.post('Booksupdate.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return Booksupdateallqqq.data;
    }
    bookplansall = async (ids) => {
        const bookplansallqqq = await Axios.post('bookplansview.php',{
            bookids:ids,
        });
        return bookplansallqqq.data;
    }
    plansall = async () => {
        const plansall = await Axios.get('price.php');
        return plansall.data;
    }
    changedplansall = async (plan,amount,ids) => {
        const changedplansallqqq = await Axios.post('changeplans.php',{
            plan:plan,
            amount:amount,
            bookids:ids,
        });
        return changedplansallqqq.data;
    }
    changedplansallupgrade = async (plan,amount,ids) => {
        const changedplansallupgradeqqq = await Axios.post('changedplansupgrade.php',{
            plan:plan,
            amount:amount,
            bookids:ids,
        });
        return changedplansallupgradeqqq.data;
    }
    bookpaysmsg = async (payids) => {
        const bookpaysmsgqqq = await Axios.post('stripesuccess.php',{
            payids:payids,
        });
        return bookpaysmsgqqq.data;
    }
    listpreviewall = async (loginids) => {
        const listpreviewallqqq = await Axios.post('listpreviewall.php',{
            loginids:loginids,
        });
        return listpreviewallqqq.data;
    }
    
    AudioBooksaddall = async (books,purchase,purchagelength,theUserid,plans,Amounts) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('written',books.written);
        formData.append('narrated',books.narrated);
        formData.append('hrs',books.hrs);
        formData.append('mins',books.mins);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('theUserid',theUserid);
        formData.append('plans',plans);
        formData.append('Amounts',Amounts);
        const AudioBooksaddallqqq = await Axios.post('AudioBooksadd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AudioBooksaddallqqq.data;
    }
    AudioBookseditall = async (books,purchase,purchagelength,bookids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('written',books.written);
        formData.append('narrated',books.narrated);
        formData.append('hrs',books.hrs);
        formData.append('mins',books.mins);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('price',books.price);
        formData.append('currency',books.currency);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('bookids',bookids);
        const AudioBookseditallqqq = await Axios.post('AudioBooksedit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return AudioBookseditallqqq.data;
    }
    VideoBooksaddall = async (books,theUserid,plans,Amounts) => {
        const VideoBooksaddallqqq = await Axios.post('VideoBooksadd.php',{
            title:books.videotitle,
            url:books.videourl,
            theUserid:theUserid,
            plans:plans,
            Amounts:Amounts,
        });
        return VideoBooksaddallqqq.data;
    }
    VideoBookseditall = async (books,booksids) => {
        const VideoBookseditallqqq = await Axios.post('VideoBooksedit.php',{
            title:books.videotitle,
            url:books.videourl,
            booksids:booksids,
        });
        return VideoBookseditallqqq.data;
    }
    addbookspublisherall = async (books,theUserid,plans,Amounts) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('email',books.email);
        formData.append('phone',books.phone);
        formData.append('theUserid',theUserid);
        formData.append('plans',plans);
        formData.append('Amounts',Amounts);
        const addbookspublisherallqqq = await Axios.post('publisheradd.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return addbookspublisherallqqq.data;
    }
    editpublisherall = async (books,bookids) => {
        const formData = new FormData();
        formData.append('title',books.title);
        formData.append('avatar',books.singlefile);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('email',books.email);
        formData.append('phone',books.phone);
        formData.append('bookids',bookids);
        const editpublisherall = await Axios.post('publisheredit.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editpublisherall.data;
    }
    editprofilsssall = async (books,purchase,purchagelength,userids) => {
        const formData = new FormData();
        formData.append('name',books.name);
        formData.append('avatar',books.singlefile);
        formData.append('website',books.website);
        formData.append('abouts',books.abouts);
        formData.append('purchase',purchase);
        formData.append('purchagelength',purchagelength.length);
        formData.append('userids',userids);
        const editprofilsssall = await Axios.post('editprofile.php',formData,{
            headers: {'content-type': 'multipart/form-data' }
        });
        return editprofilsssall.data;
    }
    audioplansall = async (ids) => {
        const audioplansall = await Axios.post('audioplansview.php',{
            bookids:ids,
        });
        return audioplansall.data;
    }
    videoplansall = async (ids) => {
        const videoplansall = await Axios.post('videoplansview.php',{
            bookids:ids,
        });
        return videoplansall.data;
    }
    publisherplansall = async (ids) => {
        const publisherplansall = await Axios.post('publisherplansview.php',{
            bookids:ids,
        });
        return publisherplansall.data;
    }
    audiopreviewall = async (ids) => {
        const audiopreviewallqqq = await Axios.post('audiopreview.php',{
            bookids:ids,
        });
        return audiopreviewallqqq.data;
    }
    videopreviewall = async (ids) => {
        const videopreviewallqqq = await Axios.post('videopreview.php',{
            bookids:ids,
        });
        return videopreviewallqqq.data;
    }
    publisherpreviewall = async (ids) => {
        const publisherpreviewallqqq = await Axios.post('publisherpreview.php',{
            bookids:ids,
        });
        return publisherpreviewallqqq.data;
    }
    userlistall = async (ids) => {
        const userlistallqqq = await Axios.post('authorprofile.php',{
            userids:ids,
        });
        return userlistallqqq.data;
    }
    bookreceiptall = async (ids) => {
        const bookreceiptallqqq = await Axios.post('bookreceipt.php',{
            booksids:ids,
        });
        return bookreceiptallqqq.data;
    }
    audioreceiptall = async (ids) => {
        const audioreceiptallqqq = await Axios.post('audioreceipt.php',{
            booksids:ids,
        });
        return audioreceiptallqqq.data;
    }
    videoreceiptall = async (ids) => {
        const videoreceiptallqqq = await Axios.post('videoreceipt.php',{
            booksids:ids,
        });
        return videoreceiptallqqq.data;
    }
    publisherreceiptall = async (ids) => {
        const publisherreceiptallqqq = await Axios.post('publisherreceipt.php',{
            booksids:ids,
        });
        return publisherreceiptallqqq.data;
    }
    Latesthomesall = async () => {
        const Latesthomesallqqq = await Axios.get('Latesthomesall.php');
        return Latesthomesallqqq.data;
    }
    Impressivehomesall = async () => {
        const Impressivehomesallqqq = await Axios.get('Impressivehomes.php');
        return Impressivehomesallqqq.data;
    }
    bestdealshomesall = async () => {
        const bestdealshomesallqqq = await Axios.get('bestdealshomes.php');
        return bestdealshomesallqqq.data;
    }
    featuredhomesall = async () => {
        const featuredhomesallqqq = await Axios.get('featuredhomes.php');
        return featuredhomesallqqq.data;
    }
    standardhomesall = async () => {
        const standardhomesallqqq = await Axios.get('standardhomes.php');
        return standardhomesallqqq.data;
    }
    audiohomesall = async () => {
        const audiohomesallqqq = await Axios.get('audiohomes.php');
        return audiohomesallqqq.data;
    }
    videohomesall = async () => {
        const videohomesallqqq = await Axios.get('videohomes.php');
        return videohomesallqqq.data;
    }
    bookpaypalall = async (payids) => {
        const bookpaypalall = await Axios.post('paypalsuccess.php',{
            payids:payids,
        });
        return bookpaypalall.data;
    }
    Impressivelist  = async (pageNumber) => {
        const Impressivelistqqq = await Axios.get(`Impressiveall.php?page=${pageNumber}`);
        return Impressivelistqqq.data;
    }
    BestDealslist  = async (pageNumber) => {
        const BestDealslistqqq = await Axios.get(`BestDealsall.php?page=${pageNumber}`);
        return BestDealslistqqq.data;
    }
    Featuredlist  = async (pageNumber) => {
        const Featuredlistqqq = await Axios.get(`Featuredall.php?page=${pageNumber}`);
        return Featuredlistqqq.data;
    }
    Standardlist  = async (pageNumber) => {
        const Standardlistqqq = await Axios.get(`Standardall.php?page=${pageNumber}`);
        return Standardlistqqq.data;
    }
    Audiolist  = async (pageNumber) => {
        const Audiolistqqq = await Axios.get(`Audioall.php?page=${pageNumber}`);
        return Audiolistqqq.data;
    }
    Trailerslist  = async (pageNumber) => {
        const Trailerslistqqq = await Axios.get(`Trailersall.php?page=${pageNumber}`);
        return Trailerslistqqq.data;
    }
    Publisherslist  = async (pageNumber) => {
        const Publisherslist = await Axios.get(`Publishersall.php?page=${pageNumber}`);
        return Publisherslist.data;
    }
    Authorslist  = async (pageNumber) => {
        const Authorslist = await Axios.get(`Authorsall.php?page=${pageNumber}`);
        return Authorslist.data;
    }
    categorylistpreviewall  = async (pageNumber,titles) => {
        const categorylistpreviewallqqq = await Axios.get(`categorylist.php?page=${pageNumber}&categorybook=${titles}`);
        return categorylistpreviewallqqq.data;
    }
    publisherhomesall = async () => {
        const publisherhomesallqqq = await Axios.get('publisherhomes.php');
        return publisherhomesallqqq.data;
    }
    storageuserall = async (ids) => {
        const storageuserallqqq = await Axios.post('storageuser.php',{
            userids:ids,
        });
        return storageuserallqqq.data;
    }
    ForgetMailpassword = async (user) => {
        const loginForget = await Axios.post('forgetpassword.php',{
            email:user.useremail,
        });
        return loginForget.data;
    }
    ChangePasswordall = async (user,passkey) => {
        const ChangePasswordallsss = await Axios.post('changePasswordall.php',{
            newpassword:user.password,
            passkeyids:passkey,
        });
        return ChangePasswordallsss.data;
    }
    Latestnewall = async () => {
        const Latestnewallsss = await Axios.get('Latestnewall.php');
        return Latestnewallsss.data;
    }
    bookdeschomesall = async () => {
        const bookdeschomesallsss = await Axios.get('bookdeschomes.php');
        return bookdeschomesallsss.data;
    }
    addnewsletters = async (user) => {
        const addnewsletterssss = await Axios.post('addnewsletters.php',{
            email:user.email,
        });
        return addnewsletterssss.data;
    }
    pintotopall = async (userids,bookids) => {
        const pintotopall = await Axios.post('pintotop.php',{
            userids:userids,
            bookids:bookids,
        });
        return pintotopall.data;
    }
    userbookspreviewall = async (userids) => {
        const userbookspreviewallsss = await Axios.post('userbooks.php',{
            userids:userids,
        });
        return userbookspreviewallsss.data;
    }
    pinsssimpressiveall = async (userids,bookids) => {
        const pinsssimpressiveallsss = await Axios.post('pintotopimpressive.php',{
            userids:userids,
            bookids:bookids,
        });
        return pinsssimpressiveallsss.data;
    }
    


    render(){
        const contextValue = {
            
            rootState:this.state,
            isLoggedIn:this.isLoggedIn,
            logoutUser:this.logoutUser,
            RegisterUser:this.RegisterUser,
            RegisterUsergoogle:this.RegisterUsergoogle,
            LoginUser:this.LoginUser,
            Booksaddall:this.Booksaddall,
            bookpreviewall:this.bookpreviewall,
            Booksupdateall:this.Booksupdateall,
            bookplansall:this.bookplansall,
            plansall:this.plansall,
            changedplansall:this.changedplansall,
            changedplansallupgrade:this.changedplansallupgrade,
            bookpaysmsg:this.bookpaysmsg,
            listpreviewall:this.listpreviewall,
            AudioBooksaddall:this.AudioBooksaddall,
            VideoBooksaddall:this.VideoBooksaddall,
            addbookspublisherall:this.addbookspublisherall,
            audioplansall:this.audioplansall,
            videoplansall:this.videoplansall,
            publisherplansall:this.publisherplansall,
            audiopreviewall:this.audiopreviewall,
            videopreviewall:this.videopreviewall,
            publisherpreviewall:this.publisherpreviewall,
            AudioBookseditall:this.AudioBookseditall,
            VideoBookseditall:this.VideoBookseditall,
            editpublisherall:this.editpublisherall,
            editprofilsssall:this.editprofilsssall,
            userlistall:this.userlistall,
            Booksaddallpacks:this.Booksaddallpacks,
            bookreceiptall:this.bookreceiptall,
            audioreceiptall:this.audioreceiptall,
            videoreceiptall:this.videoreceiptall,
            publisherreceiptall:this.publisherreceiptall,
            Impressivehomesall:this.Impressivehomesall,
            bestdealshomesall:this.bestdealshomesall,
            featuredhomesall:this.featuredhomesall,
            standardhomesall:this.standardhomesall,
            audiohomesall:this.audiohomesall,
            videohomesall:this.videohomesall,
            bookpaypalall:this.bookpaypalall,
            Impressivelist:this.Impressivelist,
            BestDealslist:this.BestDealslist,
            Featuredlist:this.Featuredlist,
            Standardlist:this.Standardlist,
            Audiolist:this.Audiolist,
            Trailerslist:this.Trailerslist,
            Publisherslist:this.Publisherslist,
            Authorslist:this.Authorslist,
            Latesthomesall:this.Latesthomesall,
            categorylistpreviewall:this.categorylistpreviewall,
            publisherhomesall:this.publisherhomesall,
            storageuserall:this.storageuserall,
            ForgetMailpassword:this.ForgetMailpassword,
            ChangePasswordall:this.ChangePasswordall,
            Latestnewall:this.Latestnewall,
            bookdeschomesall:this.bookdeschomesall,
            addnewsletters:this.addnewsletters,
            pintotopall:this.pintotopall,
            userbookspreviewall:this.userbookspreviewall,
            pinsssimpressiveall:this.pinsssimpressiveall,
           
        }
        return(
            <MyContext.Provider value={contextValue}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}

export default MyContextProvider;

//export const WEB_URL_UPLOAD = "http://localhost/books/api/";
//export const WEB_URL = "http://192.168.0.129/books/api/";

export const WEB_URL_UPLOAD = "https://intlbooks.com/api/";
export const WEB_URL = "https://intlbooks.com/api/";


export const GOOGLE_CLIENTID = "850451077658-flrft2mfphkdu0nvpp8ld9fodbtce3tt.apps.googleusercontent.com";
 export const CAPTCHA_SITE_KEY = "6Lf7BKEcAAAAAOG8Y8I3iGuTwiAW5kred2bZdxvS";



 // Paypal Link
export const PAYPAL_URL_BOOKS = "https://intlbooks.com/api/paypal/bookrequest.php";
export const PAYPAL_URL_BOOKS_UPGRADE = "https://intlbooks.com/api/paypal/bookrequestupgrade.php";
export const PAYPAL_URL_AUDIO = "https://intlbooks.com/api/paypal/audiorequest.php";
export const PAYPAL_URL_VIDEO = "https://intlbooks.com/api/paypal/videorequest.php";
export const PAYPAL_URL_PUBLISHER = "https://intlbooks.com/api/paypal/publisherrequest.php";


// New paypal php code
//https://github.com/paypal/PayPal-PHP-SDK/releases
// https://www.evoluted.net/thinktank/web-development/using-paypals-rest-api-with-php-and-mysql

//var CLIENT ='AUJoKVGO3q1WA1tGgAKRdY6qx0qQNIQ6vl6D3k7y64T4qh5WozIQ7V3dl3iusw5BwXYg_T5FzLCRguP8';
//var SECRET ='EOw8LNwDhM7esrQ3nHfzKc7xiWnJc83Eawln4YLfUgivfx1LGzu9Mj0F5wlarilXDqdK9Q5aHVo-VGjJ';

//another source code
//https://www.sanwebe.com/2014/09/paypal-rest-api-payment-system

// paypal json requset sample
//https://developer.paypal.com/docs/integration/paypal-plus/mexico-brazil/create-a-payment-request/
//https://daniela-zimmermann-immobilien.at/wp-content/plugins/estatik/vendor/paypal/rest-api-sdk-php/sample/payments/UpdatePayment.php